import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import styled from "styled-components/macro"

import GatsbyImage from "gatsby-image"

import BlockContent from "@sanity/block-content-to-react"

// This query is run before the component renders
// results are passed in as data
// The 'id' here is passed as a context to the page
export const pageQuery = graphql`
  query($id: String!) {
    sanityRecipe(id: { eq: $id }) {
      _id
      title
      description
      fullRecipeLink
      _rawBody
      photo {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`

export default function Template({ data }) {
  const recipe = data.sanityRecipe

  const Recipe = styled.div`
    background: #efefef;
    padding: 20px;
  `
  const RecipeBody = styled.article`
    margin: 20px 0;
  `
  const RecipeLink = styled.a`
    margin-top: 20px;
    border: 1px solid #36c;
    color: #36c;
    padding: 20px;
    display: block;
    border-radius: 3px;
    clear: both;
    max-width: 500px;
  `

  const Photo = styled.div`
    border-radius: 7px;
    overflow: hidden;
    @media only screen and (min-width: 450px) {
      width: 450px;
    }
    @media only screen and (min-width: 800px) {
      float: right;
    }
  `

  return (
    <Layout
      pageTitle={recipe.title}
      navigateBack={true}
      editUrl={`https://kowitz.sanity.studio/desk/recipe;${recipe._id}`}
    >
      <Recipe>
        {recipe.photo?.asset && (
          <Photo>
            <GatsbyImage fluid={recipe?.photo.asset.fluid} />
          </Photo>
        )}
        <RecipeBody>
          <p>{recipe.description}</p>
          {recipe._rawBody && <BlockContent blocks={recipe._rawBody.body} />}
        </RecipeBody>
        {recipe.fullRecipeLink && (
          <RecipeLink href={recipe.fullRecipeLink}>
            Full recipe &raquo;
          </RecipeLink>
        )}
      </Recipe>
    </Layout>
  )
}
